import React from "react";
import { useParams } from "react-router-dom";
import AvailabilityStats from "./availabilty";
import IncidentsView from "./incidents";

export default function IndexMonitoringView() {
  const { monitoringAction } = useParams();

  return (
    <div>
      {monitoringAction === "availability" && <AvailabilityStats/>}
      {monitoringAction === "incidents" && <IncidentsView/>}
    </div>
  );
}
