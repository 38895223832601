import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface AvailabilityItem {
  component: string;
  last7Days: string;
  last30Days: string;
  lastYear: string;
}

interface AvailabilityData {
  networks: AvailabilityItem[];
}

const ITEMS_PER_PAGE = 20;

export default function PublicAvailabilityStats() {
  const [availabilityData, setAvailabilityData] = useState<AvailabilityData>({
    networks: [],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const [networksPage, setNetworksPage] = useState(1);

  const [openNetworks, setOpenNetworks] = useState(false);

  useEffect(() => {
    fetchAvailabilityData();
  }, []);

  useEffect(() => {
    setNetworksPage(1);
  }, [searchQuery]);

  const fetchAvailabilityData = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: `${api_url}manager/monitoring/stats`,
      headers: {
        "x-management-key": "0xc39fd57998caedaccb1bd61c3e3195a393857eabcecb61dedeca95ff0a0328b3",
        "x-customer-slug": "admin_tool",
      },
    };
    try {
      const response = await axios.request(options);
      setAvailabilityData({ networks: response.data.networks || [] });
    } catch (error) {
      console.error("Error fetching availability data:", error);
      setAvailabilityData({ networks: [] });
    } finally {
      setLoading(false);
    }
  };

  const filterItems = (items: AvailabilityItem[]) => {
    return items.filter((item) => {
      const combined = `${item.component} ${item.last7Days} ${item.last30Days} ${item.lastYear}`.toLowerCase();
      return combined.includes(searchQuery.toLowerCase());
    });
  };

  const getPageData = (items: AvailabilityItem[], currentPage: number) => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return items.slice(start, end);
  };

  const totalPages = (items: AvailabilityItem[]) =>
    Math.ceil(items.length / ITEMS_PER_PAGE);

  const filteredNetworks = filterItems(availabilityData.networks);
  const networksData = getPageData(filteredNetworks, networksPage);
  const networksTotalPages = totalPages(filteredNetworks);

  const computeAverage = (items: AvailabilityItem[], key: keyof AvailabilityItem) => {
    if (items.length === 0) return 0;
    const sum = items.reduce((acc, item) => {
      const val = parseFloat(item[key]);
      return acc + (isNaN(val) ? 0 : val);
    }, 0);
    return sum / items.length;
  };

  const avg7Days = computeAverage(filteredNetworks, "last7Days");
  const avg30Days = computeAverage(filteredNetworks, "last30Days");
  const avgYear = computeAverage(filteredNetworks, "lastYear");

  const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,
  }: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  }) => {
    if (totalPages <= 1) return null;
    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <div className="flex justify-center space-x-1 mt-2">
        {pagesArray.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`px-3 py-1 rounded border ${
              page === currentPage
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-gray-100 text-gray-700 border-gray-300"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      {/* Header / Actions */}
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="text-3xl font-semibold">Indexing Stats</h1>
        <div className="flex space-x-4">
          <button
            onClick={fetchAvailabilityData}
            className={`px-4 py-2 rounded bg-blue-500 text-white ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 pl-4 border border-gray-300 rounded"
          />
        </div>
      </div>

      <section className="mb-8 border rounded bg-green-50 p-4">
        <h2 className="text-2xl font-semibold mb-4 bg-green-100 p-2 rounded">
          Indexing Status (All Networks)
        </h2>
        <div className="flex flex-col items-center md:flex-row md:justify-evenly">
          <div className="mb-2 md:mb-0">
            <div className="font-medium">Last 7 Days (%)</div>
            <div className="text-2xl font-bold">
              {filteredNetworks.length > 0 ? avg7Days.toFixed(4) : "N/A"}
            </div>
          </div>
          <div className="mb-2 md:mb-0">
            <div className="font-medium">Last 30 Days (%)</div>
            <div className="text-2xl font-bold">
              {filteredNetworks.length > 0 ? avg30Days.toFixed(4) : "N/A"}
            </div>
          </div>
          <div>
            <div className="font-medium">Last Year (%)</div>
            <div className="text-2xl font-bold">
              {filteredNetworks.length > 0 ? avgYear.toFixed(4) : "N/A"}
            </div>
          </div>
        </div>
      </section>

      {/* Networks Section */}
      <section className="mb-8 border rounded bg-green-50">
        <h2
          onClick={() => setOpenNetworks((prev) => !prev)}
          className="text-2xl font-semibold p-3 cursor-pointer bg-green-100 flex justify-between items-center"
        >
          <span>Networks (Click to {openNetworks ? "close" : "open"})</span>
          <span className="text-sm">{openNetworks ? "▲" : "▼"}</span>
        </h2>
        {openNetworks && (
          <div className="overflow-x-auto p-2">
            <table className="min-w-full border border-gray-200 text-center">
              <thead className="bg-green-100">
                <tr>
                  <th className="py-2 px-4 border-b">Network</th>
                  <th className="py-2 px-4 border-b">Last 7 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last 30 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last Year (%)</th>
                </tr>
              </thead>
              <tbody>
                {networksData.length > 0 ? (
                  networksData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="py-2 px-4 border-b">{item.component}</td>
                      <td className="py-2 px-4 border-b">{item.last7Days}</td>
                      <td className="py-2 px-4 border-b">{item.last30Days}</td>
                      <td className="py-2 px-4 border-b">{item.lastYear}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="py-2 px-4 text-center">
                      No Network Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={networksPage}
              totalPages={networksTotalPages}
              onPageChange={(page) => setNetworksPage(page)}
            />
          </div>
        )}
      </section>
    </Card>
  );
}