import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface AvailabilityItem {
  component: string;
  last7Days: string;
  last30Days: string;
  lastYear: string;
}

interface AvailabilityData {
  networks: AvailabilityItem[];
  instances: AvailabilityItem[];
  heartbeats: AvailabilityItem[];
}

const ITEMS_PER_PAGE = 20;

export default function AvailabilityStats() {
  const [availabilityData, setAvailabilityData] = useState<AvailabilityData>({
    networks: [],
    instances: [],
    heartbeats: [],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  // "open" states for each section
  const [openNetworks, setOpenNetworks] = useState(false);
  const [openInstances, setOpenInstances] = useState(false);
  const [openHeartbeats, setOpenHeartbeats] = useState(false);

  // Pagination states
  const [heartbeatsPage, setHeartbeatsPage] = useState(1);
  const [instancesPage, setInstancesPage] = useState(1);
  const [networksPage, setNetworksPage] = useState(1);

  useEffect(() => {
    fetchAvailabilityData();
  }, []);

  const fetchAvailabilityData = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: `${api_url}manager/monitoring/stats`,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };
    try {
      const response = await axios.request(options);
      setAvailabilityData(response.data);
    } catch (error) {
      console.error("Error fetching availability data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Whenever the search changes, reset to first page in each table
  useEffect(() => {
    setHeartbeatsPage(1);
    setInstancesPage(1);
    setNetworksPage(1);
  }, [searchQuery]);

  // Filter function
  const filterItems = (items: AvailabilityItem[]) => {
    return items.filter((item) => {
      const combined = `${item.component} ${item.last7Days} ${item.last30Days} ${item.lastYear}`.toLowerCase();
      return combined.includes(searchQuery.toLowerCase());
    });
  };

  // Filtered arrays
  const filteredNetworks = filterItems(availabilityData.networks);
  const filteredInstances = filterItems(availabilityData.instances);
  const filteredHeartbeats = filterItems(availabilityData.heartbeats);

  // Pagination helpers
  const getPageData = (items: AvailabilityItem[], currentPage: number) => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return items.slice(start, end);
  };

  const totalPages = (items: AvailabilityItem[]) =>
    Math.ceil(items.length / ITEMS_PER_PAGE);

  // Sliced data for each table
  const heartbeatsData = getPageData(filteredHeartbeats, heartbeatsPage);
  const instancesData = getPageData(filteredInstances, instancesPage);
  const networksData = getPageData(filteredNetworks, networksPage);

  // Page counts
  const heartbeatsTotalPages = totalPages(filteredHeartbeats);
  const instancesTotalPages = totalPages(filteredInstances);
  const networksTotalPages = totalPages(filteredNetworks);

  // Simple pagination component
  const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,
  }: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  }) => {
    // Don’t render if there’s only 1 or 0 pages
    if (totalPages <= 1) return null;

    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <div className="flex justify-center space-x-1 mt-2">
        {pagesArray.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`px-3 py-1 rounded border ${
              page === currentPage
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-gray-100 text-gray-700 border-gray-300"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      {/* Header / Actions */}
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="text-3xl font-semibold">Availability</h1>
        <div className="flex space-x-4">
          <button
            onClick={fetchAvailabilityData}
            className={`px-4 py-2 rounded bg-blue-500 text-white ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 pl-4 border border-gray-300 rounded"
          />
        </div>
      </div>

      {/* Heartbeats Section */}
      <section className="mb-8 border rounded bg-green-50">
        <h2
          onClick={() => setOpenHeartbeats((prev) => !prev)}
          className="text-2xl font-semibold p-3 cursor-pointer bg-green-100 flex justify-between items-center"
        >
          <span>Heartbeats</span>
          <span className="text-sm">{openHeartbeats ? "▲" : "▼"}</span>
        </h2>
        {openHeartbeats && (
          <div className="overflow-x-auto p-2">
            <table className="min-w-full border border-gray-200">
              <thead className="bg-green-100">
                <tr>
                  <th className="py-2 px-4 border-b">Heartbeat</th>
                  <th className="py-2 px-4 border-b">Last 7 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last 30 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last Year (%)</th>
                </tr>
              </thead>
              <tbody>
                {heartbeatsData.length > 0 ? (
                  heartbeatsData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="py-2 px-4 border-b text-center">
                        {item.component}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.last7Days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.last30Days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.lastYear}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="py-2 px-4 text-center">
                      No Heartbeat Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={heartbeatsPage}
              totalPages={heartbeatsTotalPages}
              onPageChange={(page) => setHeartbeatsPage(page)}
            />
          </div>
        )}
      </section>

      {/* Instances Section */}
      <section className="mb-8 border rounded bg-green-50">
        <h2
          onClick={() => setOpenInstances((prev) => !prev)}
          className="text-2xl font-semibold p-3 cursor-pointer bg-green-100 flex justify-between items-center"
        >
          <span>Instances</span>
          <span className="text-sm">{openInstances ? "▲" : "▼"}</span>
        </h2>
        {openInstances && (
          <div className="overflow-x-auto p-2">
            <table className="min-w-full border border-gray-200">
              <thead className="bg-green-100">
                <tr>
                  <th className="py-2 px-4 border-b">Instance</th>
                  <th className="py-2 px-4 border-b">Last 7 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last 30 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last Year (%)</th>
                </tr>
              </thead>
              <tbody>
                {instancesData.length > 0 ? (
                  instancesData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="py-2 px-4 border-b text-center">
                        {item.component}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.last7Days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.last30Days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.lastYear}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="py-2 px-4 text-center">
                      No Instance Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={instancesPage}
              totalPages={instancesTotalPages}
              onPageChange={(page) => setInstancesPage(page)}
            />
          </div>
        )}
      </section>

      {/* Networks Section */}
      <section className="mb-8 border rounded bg-green-50">
        <h2
          onClick={() => setOpenNetworks((prev) => !prev)}
          className="text-2xl font-semibold p-3 cursor-pointer bg-green-100 flex justify-between items-center"
        >
          <span>Networks</span>
          <span className="text-sm">{openNetworks ? "▲" : "▼"}</span>
        </h2>
        {openNetworks && (
          <div className="overflow-x-auto p-2">
            <table className="min-w-full border border-gray-200">
              <thead className="bg-green-100">
                <tr>
                  <th className="py-2 px-4 border-b">Network</th>
                  <th className="py-2 px-4 border-b">Last 7 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last 30 Days (%)</th>
                  <th className="py-2 px-4 border-b">Last Year (%)</th>
                </tr>
              </thead>
              <tbody>
                {networksData.length > 0 ? (
                  networksData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="py-2 px-4 border-b text-center">
                        {item.component}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.last7Days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.last30Days}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.lastYear}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="py-2 px-4 text-center">
                      No Network Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={networksPage}
              totalPages={networksTotalPages}
              onPageChange={(page) => setNetworksPage(page)}
            />
          </div>
        )}
      </section>
    </Card>
  );
}