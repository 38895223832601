import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface Incident {
  id: number;
  component: string;
  group_name: string;
  incident_started: string;
  incident_finished: string | null;
  active: boolean;
  channel_id: string;
  message_ts: string;
  created_at: string;
  updated_at: string;
  duration_min: number | null;
}

const ITEMS_PER_PAGE = 20;

export default function IncidentsView() {
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState<number>(8);
  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const hourOptions = [8, 24, 72, 128, "∞"] as const;

  useEffect(() => {
    fetchIncidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours]);

  useEffect(() => {
    setActivePage(1);
    setInactivePage(1);
  }, [searchQuery]);

  const fetchIncidents = async () => {
    setLoading(true);
    const url = `${api_url}manager/monitoring/incidents/${hours}`;
    const options = {
      method: "GET",
      url,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };

    try {
      const response = await axios.request<Incident[]>(options);
      setIncidents(response.data);
    } catch (error) {
      console.error("Error fetching incidents:", error);
      setIncidents([]);
    } finally {
      setLoading(false);
    }
  };

  const handleHourChange = (value: number | "∞") => {
    if (value === "∞") {
      setHours(9000);
    } else {
      setHours(value);
    }
  };

  const filteredIncidents = incidents.filter((item) => {
    const combined = `${item.component} ${item.group_name}`.toLowerCase();
    return combined.includes(searchQuery.toLowerCase());
  });

  const activeIncidents = filteredIncidents.filter((i) => i.active);
  const inactiveIncidents = filteredIncidents.filter((i) => !i.active);

  const getPageData = (items: Incident[], currentPage: number) => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return items.slice(start, end);
  };

  const totalPages = (items: Incident[]) =>
    Math.ceil(items.length / ITEMS_PER_PAGE);

  const activeData = getPageData(activeIncidents, activePage);
  const inactiveData = getPageData(inactiveIncidents, inactivePage);

  const activeTotalPages = totalPages(activeIncidents);
  const inactiveTotalPages = totalPages(inactiveIncidents);

  const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,
  }: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  }) => {
    if (totalPages <= 1) return null;

    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <div className="flex justify-center space-x-1 mt-2">
        {pagesArray.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`px-3 py-1 rounded border ${
              page === currentPage
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-gray-100 text-gray-700 border-gray-300"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  const formatDateTime = (isoString: string | null) => {
    if (!isoString) return "-";
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      {/* Hour selections and header */}
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex space-x-2">
          {hourOptions.map((option) => {
            const label = option === "∞" ? "∞" : `${option}h`;
            const value = option === "∞" ? 9000 : option;
            return (
              <button
                key={label}
                onClick={() => handleHourChange(option)}
                className={`px-4 py-2 rounded-md ${
                  hours === value ? "bg-green-600 text-white" : "bg-green-200"
                }`}
              >
                {label}
              </button>
            );
          })}
        </div>
        <h1 className="text-3xl font-semibold">Incidents</h1>
        <div className="flex items-center">
          <button
            onClick={fetchIncidents}
            className={`px-4 py-2 mr-4 rounded bg-blue-500 text-white ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 pl-4 border border-gray-300 rounded"
          />
        </div>
      </div>

      {/* Active Incidents */}
      <section className="border rounded bg-green-50 mb-8">
        <h2 className="text-2xl font-semibold p-3 bg-green-100">
          Active Incidents
        </h2>
        <div className="overflow-x-auto p-2">
          <table className="min-w-full border border-gray-200 text-center">
            <thead className="bg-green-100">
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Component</th>
                <th className="py-2 px-4 border-b">Group</th>
                <th className="py-2 px-4 border-b">Incident Started</th>
                <th className="py-2 px-4 border-b">Duration (min)</th>
              </tr>
            </thead>
            <tbody>
              {activeData.length > 0 ? (
                activeData.map((incident) => (
                  <tr key={incident.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{incident.id}</td>
                    <td className="py-2 px-4 border-b">{incident.component}</td>
                    <td className="py-2 px-4 border-b">{incident.group_name}</td>
                    <td className="py-2 px-4 border-b">
                      {formatDateTime(incident.incident_started)}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {incident.duration_min ?? "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="py-2 px-4 text-center">
                    No Active Incidents Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={activePage}
            totalPages={activeTotalPages}
            onPageChange={(page) => setActivePage(page)}
          />
        </div>
      </section>

      {/* Non-active Incidents */}
      <section className="border rounded bg-green-50">
        <h2 className="text-2xl font-semibold p-3 bg-green-100">
          Non-Active Incidents
        </h2>
        <div className="overflow-x-auto p-2">
          <table className="min-w-full border border-gray-200 text-center">
            <thead className="bg-green-100">
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Component</th>
                <th className="py-2 px-4 border-b">Group</th>
                <th className="py-2 px-4 border-b">Incident Started</th>
                <th className="py-2 px-4 border-b">Incident Finished</th>
                <th className="py-2 px-4 border-b">Duration (min)</th>
              </tr>
            </thead>
            <tbody>
              {inactiveData.length > 0 ? (
                inactiveData.map((incident) => (
                  <tr key={incident.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{incident.id}</td>
                    <td className="py-2 px-4 border-b">{incident.component}</td>
                    <td className="py-2 px-4 border-b">{incident.group_name}</td>
                    <td className="py-2 px-4 border-b">
                      {formatDateTime(incident.incident_started)}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {formatDateTime(incident.incident_finished)}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {incident.duration_min ?? "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="py-2 px-4 text-center">
                    No Non-Active Incidents Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={inactivePage}
            totalPages={inactiveTotalPages}
            onPageChange={(page) => setInactivePage(page)}
          />
        </div>
      </section>
    </Card>
  );
}